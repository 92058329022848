.clrBlack {
  width: 150% !important;
}

#dx .maincontent__card--content-icon-orange {
  z-index: 0;
}

#dx .maincontent__card--content-icon-red {
  z-index: 0;
}

#dx .maincontent__card--content-icon-green {
  z-index: 0;
}

#dx .maincontent__card--content-icon-blue {
  z-index: 0;
}

.articlePopup {
  background-color: white;
  padding: 50px !important;
  border-radius: 8px;
  width: 134% !important;
}

.moveDownInput {
  padding: 10px 12px !important;
  width: 485px !important;
}

.moveDownBtn {
  padding: 10px 0 !important;
  width: 60px;
  top: 0px;
  right: -150px;
}

.commentCard {
  box-shadow: none !important;
}

/* .commentCardBody{
    background-color: #eeeeee;
} */

.commentsList {
  margin-bottom: 2px;
  margin-top: 2px;
  font-size: small;
  /* border-bottom: 1px solid #d6d2d2; */
}

.closeIcon {
  border-radius: 20px;
  background-color: grey;
  color: white;
  height: 29px;
  width: 30px !important;
  padding: 4px;
  float: right;
}

.scroll-container {
  height: auto;
  /* Set the desired height for the scrolling area */
  overflow: auto;
  /* Enable scrolling */
}

.commentor {
  font-size: 12px;
}

.commentorProfile {
  width: 35px;
  height: 35px;
}

.dataToggleArea {
  border-radius: 0px !important;
  margin: 0px;
  font-size: 13px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* padding: 12px; */
  /* border: 2px solid; */
  display: flex;
  /* justify-content: space-evenly; */
  font-weight: 500;
  background-color: #ffffff;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
  border-radius: 4px;
}

.dataToggleArea button {
  padding: 7px !important;
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.dataToggleArea button:nth-child(5) {
  width: 34% !important;
}

.dataToggleArea button.selected {
  /* text-decoration: underline 2px solid blue; */
  color: rgb(22, 88, 160);
  font-weight: 900;
  background-color: white;
}

.sortFeedMain {
  display: inline-flex;
  margin-bottom: 10px;
  width: 100%;
}

.commonDivKMArti {
  border-radius: 4px;
  width: 50%;
  padding: 10px 10px 10px 10px;
  /* border: 1px solid grey; */
  margin: 0px 2px 4px 2px;
  color: white;
  font-size: 14px;
}

/* .mainArticleDiv{
  background-color: white;
    margin: 0px;
    padding: 0px;
    border-radius: 4px;
} */

.authorTag {
  /* display: flex;
    margin: 10px; */
  display: inline-block;
  margin: 10px 10px 10px 0px;
  width: 48%;
}

.authorTag p {
  margin: 10px 0px 10px 0px;
  font-size: 15px;
}

.authorSpan {
  font-weight: 600;
}

.rightSpan p {
  margin: 10px 0px 10px 0px;
  font-size: 15px;
}

.IconSpan {
  margin: 10px 10px 10px 0px;
}

.IconSpan2 {
  margin: 10px 0px 10px 0px;
}

.rightSpan {
  display: inline-block;
  width: 50%;
  margin: 10px 0px 10px 10px;
  text-align: end;
  font-size: 15px;
}

.articleCardHead {
  background-color: #1658a0 !important;
  color: white;
}

.articleCards {
  background-color: rgb(39 48 61);
  color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.authorButtons {
  position: relative;
  left: 310px;
}

.myArticleVM {
  color: #1475df;
  font-weight: 600 !important;
  margin-top: 12px;
  cursor: pointer;
  text-align: end;
  font-size: 14px;
}

.newContri .cygFkb {
  padding-right: 0px !important;
}

.accordion-button {
  /* background-color: #1658a0 !important; */
  color: white !important;
  padding: 10px 20px 10px 20px !important;
}

#KMArtiAccordion .accordion-button {
  padding: 10px 10px 10px 10px !important;
}

.accordion-item {
  background-color: white !important;
  border: none !important;
}

#KMArtiAccordion {
  margin-bottom: 1rem;
}

#KMArtiAccordion .accordion-button::after {
  /* color: white !important;
  background-color: white;
  border-radius: 50%; */
  display: none;
}

.ArtExpandIcons {
  background-color: white;
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
  padding: 4px;
  color: blue;
  float: right;
  margin-left: auto;
}

#KMArtiAccordion .accordion-button.collapsed::after {
  /* color: white !important; */
  display: none;
}

#dx .maincontent__card--content-woheader {
  min-height: 0px !important;
}

/* .accordion-button:not(.collapsed)::after {
color: white !important;
} */

.ArticleDesc {
  width: fit-content;
  word-wrap: break-word;
  word-break: break-word;
}

.error-message {
  color: red;
  font-size: 10px;
  font-weight: 600;
}

#myArticleStatus {
  font-size: 14px;
  font-weight: 600;
}

.sortBy {
  float: right;
  border: 1px solid rgb(106, 105, 105);
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}

.sort-options {
  margin-bottom: 1rem;
}

.kmArtiImgCard {
  width: 50%;
  display: inline-flex;
}

.searchForArti {
  display: flex;
  margin-bottom: 1rem;
}

.search-containerKMArti {
  width: 80%;
}

.sort-optionsKMArti {
  width: 20%;
}

.searchArtiInput {
  padding: 7px 7px 7px 40px;
  width: 60%;
  border-radius: 12px;
  border: 1px solid gray;
  font-size: 14px;
}
.searchArtiInput:focus {
  outline: none;
}
.sortFeedLine {
  width: 100%;
  position: relative;
  top: 10px;
}

.sortfeedhr {
  width: calc(100% - 150px);
  margin-top: 0px;
  /* margin-left: 21px; */
  /* float: left; */
  /* height: 0; */
  padding: 0px;
  display: flex;
}

.sortfeedSelect {
  /* background-color: #1658a0;
  color: white;
  float: right; */
  color: black;
  float: right;
  background-color: transparent;
  font-weight: 600;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/img/icons/chevron-down-solid.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 12px;
  padding: 0 15px 0 5px;
}
.articles__sortfeedSelect {
  color: #000 !important;
  /* background-image: url("../../assets/img/icons/chevron-down-solid-white.svg"); */
}

.sortfeedSelect:focus {
  outline: none !important;
  border: none !important;
}

.searchIconKMArti {
  display: inline-block;
  padding: 0px;
  /* background-color: white; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  /* height: 100%; */
  position: relative;
  right: 373px;
  bottom: -7px;
  opacity: 40%;
}

.resetBtnKMA {
  background-color: #1658a0;
  color: white;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.resetIconKM {
  color: rgb(22, 88, 160);
  cursor: pointer;
  float: right;
  border: 1px solid lightgray;
  padding: 6px;
  border-radius: 3px;
  height: 35px;
  width: 35px !important;
  background-color: #d8dae1;
  position: relative;
  top: -35px;
  left: 45px;
}

.resetIconKMArti {
  color: rgb(22, 88, 160);
  cursor: pointer;
  display: inline-block;
  /* float: right; */
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 8px;
  height: 45px;
  width: 41px !important;
  background-color: white;
}

.filepath {
  color: blue !important;
}

.apexcharts-yaxis-title .apexcharts-text {
  font-weight: 600 !important;
  color: grey !important;
}

.imgiconKMD {
  position: relative !important;
  bottom: 25px !important;
}

#dx .kmarticle-seactform {
  width: 500px;
}

.ArticleDetailss {
  /* border: 1px solid lightgrey; */
  display: flex;
  padding: 0px 10px 0px 10px;
  border-radius: 8px;
  background-color: #ebebeb;
  float: right;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.ArticleDetailss div {
  margin: 4px;
}

.likesncmts {
  display: flex;
  /* justify-content: end; */
  margin: 0px !important;
  width: 100%;
}

.breadCrumbHeader {
  color: #050346 !important;
  cursor: pointer !important;
}

.cmtoptions {
  display: none;
}

.commentsList:hover .cmtoptions {
  display: inline-block;
}

/* Styles for the modal popup */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 0% auto;
  width: 100%;
  max-height: 85vh;
}
.modal-content .closeIcon {
  right: -25px !important;
}
.modal-backdrop.show {
  display: none;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.cardBorderLine {
  border-left: 2px solid white;
  position: absolute;
  right: 264px;
  /* left: 22px; */
  height: 45px;
  top: 77px;
}

.cardBorderLine2 {
  border-left: 2px solid white;
  position: absolute;
  right: 74px;
  /* left: 22px; */
  height: 45px;
  top: 77px;
}

.cardBorderLine3 {
  border-left: 2px solid white;
  position: absolute;
  right: 264px;
  /* left: 22px; */
  height: 45px;
}

.cardBorderLine4 {
  border-left: 2px solid white;
  position: absolute;
  right: 74px;
  /* left: 22px; */
  height: 45px;
}
.hide_sub_reply_box {
  display: none;
}

.accordion-content-active {
  display: block;
  margin-left: 50px;
}
.accordion-content-active #sub_replay_cmt {
  left: 55%;
}
.reply_space {
  margin-left: 50px;
}

.font_small {
  font-size: small;
}
#dx .maincontent__card--kmArticles-articleResponse {
  padding: 9px 65px 5px 12px;
}

#dx .maincontent__card--kmArticles-articleBtn {
  right: -10px;
}

.top_search_clear_icon {
  display: inline-block;
  padding: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  right: 20px;
  bottom: -7px;
  opacity: 40%;
}

.badgeClass {
  display: flex;
  align-items: center;
}

.badgeClass2 {
  display: flex;
  align-items: baseline;
}
.chatGpt-conversation-btn{
  padding: 5px !important;
  border-radius: 10px !important;
  right: 10px !important;
  top: 10px;
}

@media (max-width: 767px) {
  .resetIconKM {
    float: right;
    border: 1px solid lightgray;
    padding: 1px;
    border-radius: 3px;
    height: 32px;
    width: 23px !important;
    background-color: #d8dae1;
    position: relative;
    top: -35px;
    left: 30px;  }
}

@media (max-width: 767px) {
  #dx .kmarticle-seactform {
    width: 250px;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
 .button {
  cursor: pointer;
  padding-left: 22px !important;
  padding-right: 24px !important;
  }
}
