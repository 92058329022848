#dx {
  $dark-blue: #050346;
  $gray-40: rgba(32, 32, 32, 0.4);
  $gray-100: #202020;
  $primary-blue: #1658a0;
  $primary-green: #05a677;
  $hover-primary-blue: #346ead;
  $hover-primary-green: #05a677;
  $white: #ffffff;
  $sky-blue: #1475df;
  $hover-sky-blue: #237ddd;
  $light-blue: #f2f8ff;
  $border-blue: #d5e9ff;
  $gray-20: #7c7c7c;
  $gray-10: #e2e2e2;
  $green: #8bc34a;
  $green-100: #119d6c;
  $red: #f15757;
  $hover-red: #f46b6b;
  $yellow: #c69800;
  $light-gray: rgba(240, 240, 240, 0.6);
  $text-gray: #464a53;
  $text-black: #000000;
  $light-icon-green: #ddf0ee;
  $light-icon-blue: #dee6f9;
  $light-icon-orange: #fce1d8;
  $light-icon-red: #f9d9de;
  $light-icon-maroon: rgba(235, 96, 119, 24%);
  $light-icon-aqua: rgba(119, 221, 230, 24%);
  $light-icon-yellow: rgba(238, 204, 89, 24%);
  $light-icon-purple: rgb(164, 89, 238, 24%);
  $light-icon-limegreen: rgba(152, 238, 89, 24%);
  $light-icon-skyblue: rgba(89, 204, 238, 24%);
  $light-icon-litepurple: rgba(238, 89, 221, 24%);
  $dark-icon-green: #73bfb2;
  $dark-icon-blue: #739de5;
  $dark-icon-orange: #e88359;
  $dark-icon-red: #ea5f76;

  .maincontent {
    // margin-left: 280px;
    background-color: #eceef6;
    padding: 0 20px 20px 20px;
    min-height: calc(100vh - 60px);
    &__breadcrumb {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      font-size: 16px;
      color: $dark-blue;
      margin: 0.5rem 0;
      &--active {
        color: $gray-40;
      }
      &--divider {
        color: $gray-40;
        font-size: 12px;
      }
    }
    &__pageheader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.5rem 0;
    }
    &__card {
      display: flex;
      flex-direction: column;
      &--header {
        background-color: #d0d2d9 !important;
        border-radius: 20px;
        padding: 10px 0;
        &-title {
          font-size: 15px;
          font-weight: 600;
          color: #000000;
          text-transform: uppercase;
          padding: 6px 10px;
          margin: 0;
          border-radius: 8px 8px 0 0;
          // background-color: #d5daf9;
        }
      }
      &--content {
        padding: 1rem;
        // box-shadow: 2px 2px 8px $gray-40;
        // border-radius: 0 0 8px 8px;
        position: relative;
        color: $text-black;
        // background: #fff !important;
        &-brfull {
          border-radius: 8px;
        }
        &-woheader {
          border-radius: 20px;
          min-height: 130px;
          color: #7d7d7d;
        }
        &-circleWithIcon {
          width: 135px;
          height: 120px;
          border-radius: 50%;
          position: absolute;
          right: -50px;
          top: -50px;
          margin-right: 0;
          &-blue {
            background-color: $light-icon-blue;
          }
          &-orange {
            background-color: $light-icon-orange;
          }
          &-green {
            background-color: $light-icon-green;
          }
          &-red {
            background-color: $light-icon-red;
          }
          &-limegreen {
            background-color: $light-icon-limegreen;
          }
          &-aqua {
            background-color: $light-icon-aqua;
          }
          &-purple {
            background-color: $light-icon-purple;
          }
          &-yellow {
            background-color: $light-icon-yellow;
          }
          &-maroon {
            background-color: $light-icon-maroon;
          }
          &-skyblue {
            background-color: $light-icon-skyblue;
          }
          &-litepurple {
            background-color: $light-icon-litepurple;
          }
        }
        &-icon {
          position: absolute;
          right: 10px;
          top: 15px;
          font-size: 24px;
          width: 25px;
          height: auto;
          &-blue {
            color: $dark-icon-blue;
          }
          &-red {
            color: $dark-icon-red;
          }
          &-green {
            color: $dark-icon-green;
          }
          &-orange {
            color: $dark-icon-orange;
          }
        }
        &-articles {
          padding: 3px 6px;
          border: 1px solid #827cbc;
          background-color: rgba(130, 124, 188, 0.2);
        }
        & .bi-cloud-arrow-up {
          fill: $primary-blue;
        }
        & .input-font {
          border-radius: 3px;
        }
      }
      &--body {
        background: #e3e5ec;
        border-radius: 20px;
      }
      &--tableheader {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // margin-bottom: 1rem;
        &-search {
          position: relative;
        }
        &-searchicon {
          position: absolute;
          right: 12px;
          top: 8px;
        }
        &-input {
          border: 1px solid $border-blue;
          background-color: $light-blue;
          padding: 6px;
          border-radius: 10px;
          font-size: 12px;
        }
        &-right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 1rem;
        }
        &-entries {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          background-color: $light-blue;
          border: 1px solid $border-blue;
          border-radius: 10px;
          padding: 5px 8px;
          gap: 10px;
          & p {
            font-size: 12px;
            color: $gray-20;
          }
          & select {
            border-radius: 0;
            background-color: $white;
            padding: 2px 4px;
            font-size: 12px;
            color: $gray-20;
            border: 1px solid $gray-10;
          }
          & option {
            font-size: 12px;
            color: $gray-20;
          }
        }
      }
      &--label {
        color: $text-gray;
        font-weight: 500;
      }
      &--input {
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid $gray-20;
      }
      &--kmDashboard {
        &-contribution {
          grid-area: 2 / span 2;
        }
        &-summaryrow {
          display: grid;
          grid-template-columns: repeat(3, auto);
          gap: 1rem;
          color: $text-black;
        }
        &-contributors {
          display: flex;
          gap: 8px;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $gray-20;
          padding-bottom: 15px;
          &:not(:first-child) {
            padding-top: 15px;
          }
        }
        &-posttag {
          background-color: $sky-blue;
          padding: 4px 8px;
          border-radius: 5px;
          color: $white;
          font-size: 14px;
          min-width: 80px;
          text-align: center;
        }
        &-contributorsPic {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        &-contributorBorder {
          width: 2px;
          height: 16px;
          background-color: $gray-20;
        }
      }
      &--kmArticles {
        &-article {
          border: 1px solid $gray-20;
          padding: 1rem;
          // margin: 1rem 0;
          color: $text-black;
        }
        &-articleBorder {
          @extend .maincontent__card--kmDashboard-contributorBorder;
          height: 25px;
        }
        &-articlePic {
          @extend .maincontent__card--kmDashboard-contributorsPic;
        }
        &-articleResponse {
          padding: 5px 65px 5px 12px;
          border-radius: 15px;
          border: 1px solid $gray-20;
          flex: 1;
          width: 100%;
        }
        &-articleBtn {
          border-radius: 0 15px 15px 0;
          background-color: $primary-blue;
          padding: 5px 0;
          color: $white;
          position: absolute;
          right: 0;
          width: 60px;
        }
      }
    }
    &__table {
      &--rag {
        width: 42px;
        height: 23px;
        border-radius: 5px;
        padding: 4px;
        &-notupdated {
          background-color: $yellow;
        }
        &-updated {
          background-color: $green;
        }
      }
      &--status {
        color: $white;
        // padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 91px;
        height: 23px;
        font-size: 10px;
        font-weight: 600;
        border-radius: 5px;
        text-align: center;
        &-updated {
          background-color: $green;
        }
        &-notupdated {
          background-color: $red;
        }
      }
    }
    &__h1 {
      font-size: 30px;
      font-weight: 400;
      color: $gray-100;
      margin: 0;
    }
    &__btn {
      color: $white;
      min-width: 140px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: none;
      font-size: 14px;
      font-weight: 400;
      padding: 0 15px;
      transition: all 500ms ease-in-out;
      &--blue {
        background-color: $sky-blue;
        &:hover {
          background-color: $hover-sky-blue;
        }
      }
      &--primaryblue {
        background-color: $primary-blue;
        &:hover {
          background-color: $hover-primary-blue;
        }
      }
      &--primarygreen {
        background-color: $primary-green;
        &:hover {
          background-color: $hover-primary-green;
        }
      }
      &--red {
        background-color: $red;
        &:hover {
          background-color: $hover-red;
        }
      }
    }
    &__faIcon {
      font-size: 24px;
      &--redcolor {
        color: $red;
      }
    }
    &__postarticle {
      background-color: #e3e5ec;
      border-radius: 20px;
      padding: 1rem 2rem;
      width: 100%;
      margin: 0 auto;
      // display: none;
      &--select {
        border: 1px solid $gray-20;
        border-radius: 10px;
        padding: 0.5rem 1rem;
      }
      &--input {
        @extend .maincontent__postarticle--select;
      }
      &--attachicon {
        background-color: $gray-10;
        border-radius: 5px;
        padding: 8px;
        width: 30px;
        height: 30px;
      }
    }
    &__input {
      height: auto;
      overflow: visible;
      background-color: red;
      border: 1px solid red;
    }
    &__accordion {
      &--header {
        background-color: $primary-blue;
        padding: 8px;
        color: $white;
        font-weight: 600;
        border-radius: 8px;
        outline: none;
      }
    }
    .accordion-header {
      position: relative;
      z-index: 0;
    }
  }
  .form-label {
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 400;
  }
  .css-1fdsijx-ValueContainer {
    font-size: 14px;
  }
  /* .css-13cymwt-control {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  .form-control {
    border-radius: 4px;
  } */
  .input-group {
    max-height: 38px;
    flex-wrap: nowrap;
    // z-index: 1;
    & .input-group-prepend {
      margin-right: 0;
      & + .form-control {
        border-radius: 0 4px 4px 0;
      }
      & + .css-b62m3t-container {
        & .css-13cymwt-control {
          border-radius: 0 4px 4px 0;
          border-left: none;
          max-height: 100px;
          overflow-y: auto;
        }
      }
      & .icon-container {
        border-radius: 4px 0 0 4px;
        height: 38px;
        background: #cdcfd7;
      }
    }
  }
  textarea.form-control {
    border-radius: 4px;
  }
  input[type="week"].form-control,
  .formconus.form-control,
  [type="month"] {
    border-radius: 4px;
  }
  .modal-dialog {
    max-width: 50%;
  }
  .formconus.form-control {
    width: 100%;
  }
  .role-popup {
    /* & .modal-content:first-of-type {
      align-items: center;
    } */
    & .modal-dialog {
      max-width: 75% !important;
    }
    /* & .rhdivCard {
      width: 100%;
    } */
  }
  .modal-content {
    align-items: center;
  }
  /*  .dashboard-graph-section {
    height: 20vh;
  } */
  .rhdivCard {
    width: 100%;
  }
  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
  .input-icon {
    width: 25px;
    height: auto;
  }
  .accordion .accordion-item {
    background: transparent !important;
  }
  .accordion .accordion-button {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .accordion .accordion-button.collapsed {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .accordion .accordion-body {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .card-body {
    padding-top: 0;
    height: 100%;
    .articles__article--header {
      width: 100%;
      background: #fff;
      position: sticky;
      top: 0;
      padding: 7px 0;
    }
  }
  .ck-content{
    &.ck-editor__editable{
      ol{
        list-style-position: inside;
      }
      ul{
        list-style-position: inside;
      }
      h2{
        font-size: 28px;
      }
      h3{
        font-size: 24px;
      }
      h4{
        font-size: 20px;
      }
    }
  }
  /* .card {
    height: 100%;
  } */
  /***********************************
****** Desktop Utility Classes *****
***********************************/

  // Flex utility
  .flexVerandHorCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .disFlex {
    display: flex;
  }
  .justifyBetween {
    justify-content: space-between;
  }
  .justifyEnd {
    justify-content: flex-end;
  }
  .alignCenter {
    align-items: center;
  }
  .flexDirColumn {
    flex-direction: column;
  }
  .gap1 {
    gap: 1rem;
  }
  .gap05 {
    gap: 0.5rem;
  }
  .gap2 {
    gap: 2rem;
  }
  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }

  // Width utility
  .w100 {
    width: 100%;
  }
  .w75 {
    width: 75%;
  }
  .w50 {
    width: 50%;
  }

  // Margin utility
  .mtb1 {
    margin: 1rem 0;
  }
  .mt1 {
    margin-top: 1rem;
  }
  .mr1 {
    margin-right: 1rem;
  }
  .ml1 {
    margin-left: 1rem;
  }
  .ml05 {
    margin-left: 0.5rem;
  }

  // Border utility
  .bb1 {
    border-bottom: 1px solid $gray-100;
  }
  .br1 {
    border-right: 1px solid $gray-10;
  }

  // Padding utility
  .pb05 {
    padding-bottom: 0.5rem;
  }
  .ptb05 {
    padding: 0.5rem 0;
  }
  .ptb1 {
    padding: 1rem 0;
  }

  // Font Color utility
  .clrGreen {
    color: $green-100;
  }
  .clrBlue {
    color: $sky-blue;
  }
  .clrGray {
    color: $gray-20;
  }
  .clrBlack {
    color: $text-black;
  }
  .clrPrimaryBlue {
    color: $primary-blue;
  }
  .clrPrimaryGreen {
    color: $primary-green;
  }
  // Font size utility
  .fz14 {
    font-size: 14px;
  }
  .fz12 {
    font-size: 12px;
  }
  .fz20 {
    font-size: 20px;
  }
  .fz24 {
    font-size: 24px;
  }

  // Font weight utility
  .fw700 {
    font-weight: 700;
  }
  .fw600 {
    font-weight: 600;
  }

  //Position utility
  .posRelative {
    position: relative;
  }

  /***********************************
*********** Data Table CSS *********
***********************************/
  .rdt_TableCell [data-tag="allowRowEvents"] {
    display: flex;
    gap: 8px;
  }
  .hgByPU div:first-child {
    text-align: left;
  }
  // .rdt_TableCol_Sortable .brFloq {
  //   text-align: left;
  // }
  .rdt_TableRow:nth-child(even) {
    background-color: $light-gray;
  }
  .DfDUN {
    border-radius: 5px;
    padding: 8px;
  }
  .modal-content {
    & .sc-fqkvVR {
      padding-left: 16px;
    }
    & .rhdivCard {
      // background-color: #d5daf9;
      & p {
        color: #000000;
      }
    }
  }
  input{
    .form-control{
      .selectOptions{
        color: hsl(0, 0%, 20%);
        font-size: 14px;
      }
    }
  }
  #myChart-svg{
    transform: translate(0, 16px);
  }
  #myChart-legend{
    transform: translate(0, -16px);
  }
  .MuiChartsLegend-root{
    transform: translate(0, -30px);
  }
}

@media (max-width: 767px) {
  #dx {
    .maincontent {
      margin-left: 0;
      &__breadcrumb {
        font-size: 12px;
        & img {
          width: 22px;
        }
      }
      &__card {
        &--content {
          padding: 1rem;
        }
        &--tableheader {
          justify-content: center;
          &-right {
            gap: 5px;
            justify-content: center;
          }
        }
      }
    }
    .dataToggleArea {
      flex-direction: column;
      gap: 10px;
    }
  }
}

@media (max-width: 400px) {
  #dx {
    & .maincontent {
      &__btn {
        min-width: 120px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  #dx {
    & .maincontent {
      margin-left: 0;
      &__postarticle {
        width: 100%;
      }
      /* &__card {
        &--kmDashboard {
          &-contributors {
            flex-direction: column;
          }
          &-posttag {
            width: 100%;
          }
        }
      } */
    }
  }
}
@media (max-width: 1024px) {
  #dx {
    & .modal-dialog {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media (min-width: 1920px) {
  #dx {
    .css-1fdsijx-ValueContainer {
      font-size: 16px;
    }
    .form-label {
      font-size: 16px;
    }
  }
}

/**********************************
******* New Articles Page**********
**********************************/
$article-navy-blue: #050346;
$articles-yellow: #b9a018;
$articles-green: #0e9d86;
$white: #fff;
$black: #000;
$gray: #797979;
$articles-dark-aqua: #8ce5ef;
$articles-light-aqua: #d8f3f6;
$articles-medium-aqua: #aaf1f9;
$articles-dark-purple: #c8cfff;
$articles-light-purple: #dee2fd;
$articles-dark-pink: #ffb2d1;
$articles-light-pink: #fcd6e5;

.articles {
  &__container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    color: $black;
  }
  &__search {
    display: flex;
    gap: 8px;
    color: $white;
    align-items: center;
    padding-bottom: 1rem;
    // border-bottom: 1px solid #707070;
    &--input {
      padding: 1rem 10rem 1rem 1rem;
      border-radius: 28px;
      width: 100%;
      color: $black;
      &::placeholder {
        color: $gray;
      }
    }
    &--searchbtn {
      position: absolute;
      right: 8px;
      top: 7px;
      background-color: $article-navy-blue !important;
    }
    &--postbtn {
      background-color: $articles-yellow !important;
      min-width: 100%;
    }
    &--reviewbtn{
      background: #050346;
      min-width: 100%;
      &:hover{
        background: #346ead;
      }
    }
    &--sortbtn {
      background-color: $articles-green !important;
    }
    &--btn {
      border-radius: 2rem;
      width: 100%;
      height: auto;
      padding: 10px !important;
      font-size: 14px;
      height: 38px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__searchresult {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid $articles-medium-aqua;
    border-radius: 16px;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 16%);
    background: #fff;
    p {
      color: #000;
    }
  }
  &__tags {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    background: transparent;
    &--tag {
      border-radius: 20px;
      padding: 3.5px 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      flex-direction: column;
      color: $black;
    }
    &--level {
      background-color: $articles-dark-aqua;
    }
    &--reward {
      background-color: $articles-dark-purple;
    }
    &--articles {
      background-color: $articles-light-purple;
    }
    &--published {
      background-color: $articles-dark-pink;
    }
    &--title {
      font-size: 16px;
      font-weight: 600;
    }
    &--line {
      width: 20%;
      height: 1px;
      background-color: $black;
    }
    &--data {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &__articlelist {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;
    padding: 0 0 1rem 0;
    gap: 8px;
  }
  &__article {
    padding: 8px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 210px;
    row-gap: 5px;
    &--header {
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: center;
    }
    &--purple {
      background-color: $articles-light-purple;
    }
    &--aqua {
      background-color: $articles-light-aqua;
    }
    &--pink {
      background-color: $articles-light-pink;
    }
    &--lightgray {
      background-color: rgba(212, 214, 222, 0.5);
    }
    &--darkgray {
      background-color: rgba(212, 214, 222, 0.9);
    }
    &--profilepic {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    &--author {
      font-size: 12px;
      font-weight: 600;
      color: #1b2fd5;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &--level {
      font-size: 10px;
      font-weight: 400;
      color: #5b5b5b;
      max-width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    &--line {
      width: 0.5px;
      height: 25px;
      background-color: #aa174b;
    }
    &--likes {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    &--likescount {
      color: #c6195c;
      font-size: 12px;
    }
    &--comments {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    &--commentscount {
      color: #6219c6;
      font-size: 12px;
    }
    &--date {
      font-size: 12px;
      font-weight: 600;
      max-width: 100%;
      min-width: 20%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &--img {
      border-radius: 20px;
      width: 100%;
      height: auto;
    }
    &--readmorebtn {
      background-color: rgba(255, 255, 255, 60);
      margin: 5px 0 0 0 !important;
      border-radius: 22px;
      width: 120px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
      font-size: 12px;
      font-weight: 600;
      padding: 0 !important;
    }
    &--title {
      font-size: 30px;
      font-weight: 600;
      margin-top: 3px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &--para {
      font-size: 13px;
      line-height: 20px;
      max-height: 60px;
      overflow-y: auto;
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 13px;
        line-height: 20px;
      }
      ul {
        li {
          font-size: 13px;
          line-height: 20px;
        }
      }
      img {
        margin: 6px 0;
        height: 180px;
        width: 100%;
        object-fit: scale-down;
      }
      &::-webkit-scrollbar{
        display: none;
      }
    }
    &--spancolumn {
      grid-row: span 2;
    }
  }
  &__left {
    position: relative;
    margin-top: 0.5rem;
  }
  &__right {
    display: flex;
    flex-direction: column;
    gap: 13px;
    background: #e1eef9;
    padding: 0.5rem 20px 20px 20px;
  }
  &__rightcard {
    border-radius: 33px;
    &--header {
      border-radius: 33px;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: 600;
    }
    &--lists {
      display: flex;
      flex-direction: column;
    }
    &--list {
      padding: 8px 16px;
      border-bottom: 1px solid #aeaeae;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 3px;
    }
    &--date {
      font-size: 13px;
    }
    &--title {
      font-size: 15px;
      font-weight: 500;
      white-space: nowrap;
    }
    &--status {
      @extend .articles__rightcard--title;
    }
    &--published {
      color: #427e20;
    }
    &--draft {
      color: #ff0000;
    }
    &--left {
      display: flex;
      flex-direction: column;
    }
  }
  &__myarticles {
    // background-color: #c2cdd7;
    display: flex;
    align-items: center;
    &--header {
      background-color: #c2cdd7;
    }
  }
  &__events {
    background-color: #d4e0eb;
    &--header {
      background-color: #c2cdd7;
    }
  }
  &__contributors {
    background-color: $articles-light-aqua;
    &--header {
      background-color: $articles-dark-aqua;
    }
    &--tag {
      background-color: $articles-dark-aqua;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 600;
      width: 93px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--profilepic {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    &--batch {
      width: 20px;
      height: auto;
    }
    &--list {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #aeaeae;
      padding: 15px;
    }
  }
  &__readmorebtn {
    border-radius: 22px;
    background-color: #c6d0da;
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    font-size: 12px;
    font-weight: 600;
    margin: 8px 16px;
    padding: 0 !important;
  }
  &__attachments {
    display: flex;
    gap: 4rem;
    padding: 1rem 0;
    &--img {
      width: 30px;
      height: 30px;
    }
  }
  &__comments {
    width: 100%;
    position: relative;
    &--input {
      @extend .articles__search--input;
      height: 70px;
      border-radius: 30px;
      appearance: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &--btn {
      padding: 1rem;
      border-radius: 30px;
      background-color: $article-navy-blue !important;
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }
}
.card {
  &__container {
    &--articles {
      border-radius: 24px;
    }
  }
  &__line {
    width: 95%;
    height: 1px;
    background: rgba(0, 0, 0, 0.7);
    margin: 4px 0;
  }
  &__header {
    border-radius: 24px;
    padding: 10px 15px;
    font-size: 17px;
    font-weight: 600;
  }
  &__profilepic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__articles {
    &--title {
      width: 100%;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    &--listsec:not(:last-child) {
      border-bottom: 1px solid #aeaeae;
    }
  }
  &__content {
    border-radius: 24px;
  }
  .articles__article {
    height: auto;
  }
}
@media (max-width: 767px) {
  #dx {
    .articles {
      &__container {
        grid-template-columns: 100%;
      }
      &__search {
        flex-direction: column;
        &--searchbtn {
          width: 100px;
        }
        &--postbtn {
          width: 100%;
        }
        &--sortbtn {
          width: 100%;
        }
      }
      &__articlelist {
        grid-template-columns: 100% !important;
      }
    }
    .modal-content {
      .articles {
        &__article {
          &--header {
            flex-direction: column;
            text-align: center;
          }
          &--line {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 767px) and (max-width: 1300px) {
  #dx {
    .articles {
      &__right {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media (max-width: 1300px) {
  #dx {
    .articles {
      &__container {
        grid-template-columns: 100%;
      }
      &__left {
        .MuiPagination-root {
          margin-left: 0 !important;
        }
      }
      &__right{
        margin-top: 1rem;
      }
    }
  }
}
