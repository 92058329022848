/* .leftBoxx {
    background-image: url(../../assets/img/SigninBg.jpg);
    background-repeat: no-repeat;
    flex: 45%;
    background-size: contain;
    background-color: white;
  } */

  .rightSideBox {
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    /* height: 100vh; */
    /* width: 100%; */
    overflow: hidden auto;
    background-color: white;
    max-height: 100vh;
    position: relative;
}

.midSections input {
  width: 100%;
  padding: 10px 14px;
  border: 2px solid #d7daddad;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnButton{
  background-color: rgb(5, 8, 74) !important;
  width: 100%;
  margin-top: 5%;
  color: white;
}

.rightBoxx{
  padding: 20% 10% 0% 10% !important;
  text-align: center;
}

@media (min-width: 400px) and (max-width: 980px){
  .SignInLink{
    margin: 0% 0% 20% 0% !important;
  }
}