@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: 'Inter', sans-serif;
}
main {
  background-color: #eceef6;
}
button,
input,
select,
option {
  font-family: 'Inter', sans-serif;
}
.gVZIpW {
  min-height: 36px;
}
.dashfistcard {
  padding: 3px;
  font-size: 12px;
}
.dashfistcard span {
  font-size: large;
  font-weight: bold;
  color: #8165f4;
}
.piechart .chart-wrapper canvas {
  height: 230px;
  max-height: 230px;
}
.barchart .chart-wrapper canvas {
  height: 230px;
  max-height: 230px;
}

.dashboard-bar {
  width: 50px;
  height: 20px;
  border-radius: 30px;
}

.active_bar {
  background: rgb(222, 193, 33);
}
.complete_bar {
  background: rgb(17, 227, 58);
}
.cancel_bar {
  background: rgb(255, 0, 0);
}
.hold_bar {
  background: yellow;
}
div {
  white-space: pre-wrap;
}

.flag_head {
  padding: 3px;
  margin: 0px;
  font-weight: bold;
  color: white;
}
.status_col {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 25%;
}
.raid_table td {
  padding-left: 0.85rem;
  padding-right: 0.25rem;
  width: 10%;
  border-radius: 5px;
}
.team_compos_tab td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 12.5%;
}
.form-control {
  padding: 0.25rem 0.25rem;
}
.viewhistory_tab th {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background: skyblue;
}
th {
  background: skyblue;
}

table th,
table td {
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  height: 50px;
}
.entries {
  background-color: white;
}

/* [data-column-id="8"] {
  min-width: 120px;
} */

label {
  font-size: 14px;
}

.icon-container {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  /* padding: 0.475rem 0.85rem; */
  border: 1px solid #ced4da;
  border-radius: 0.29rem;
  height: 37.5px;
}
.space_btn {
  margin-right: 4px;
}
.icon-container svg {
  color: #888888;
  font-size: 1rem;
  height: 21px;
}

/* .brFloq {
  font-weight: bold;
  text-align: center;
} */

.keobJZ {
  min-width: 93px;
  max-width: 110px;
}

.form-control {
  /* border: 1px solid #e2e2e2; */
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px !important;
}

.hgByPU div:first-child {
  text-align: center;
}

input[type="text"] {
  color: black;
}
input[type="date"] {
  color: black;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  /* min-width: 81%; */
  width: 100%;
  max-height: 38px;
}

.btn-sm {
  margin-bottom: 10px;
  margin-right: 20px;
  /* background: none; */
  padding-left: 10px;
  padding-right: 10px;
  border-style: none;
}
.css-16xfy0z-control {
  background-color: hsl(0deg 0% 100%);
}

.raidtable {
  height: 37px;
  border-radius: 5px;
}

.raidtabledesc {
  height: 38px;
  width: 110%;
  border-radius: 5px;
  margin-right: 15px;
}

.raidtablebtn {
  padding-left: 20px;
  padding-right: 20px;
}

.raidtableth {
  padding-left: 30px;
}

.table td,
.table th {
  font-size: 0.75rem;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #050346;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #050346;
}

.table thead th {
  height: 30px;
}

.raids_table.table > :not(caption) > * > * {
  padding: 2px 2px;
}
table.raids_table td {
  padding: 0 2px 0 2px;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1dimb5e-singleValue {
  white-space: nowrap;
}
.css-1xc3v61-indicatorContainer {
  padding: 0px;
}

#dx .faPlus {
  background-color: #073686;
}
/* #dx .maincontent__card--header-title {
  background-color: #1658a0;
  padding: 0.25rem 0.5rem;
} */

#dx #team_compos {
  font-size: 14px;
  font-weight: 400;
  padding: 3px;
}

textarea {
  font-size: 14px;
  font-weight: 500;
}
#dx .cursor_pointer {
  cursor: pointer;
}
/* #dx span {
  cursor: context-menu;
} */
#dx #fileImgIcon {
  border: none;
}

#dx .maincontent__card--kmArticles-article {
  border: 1px solid #83838342 !important;
}

/* #dx .maincontent__breadcrumb {
  margin-bottom: 4px;
} */

#dx .btn-info {
  background-color: #1658a0;
  margin-left: 4px;
  margin-right: 4px;
}

#dx .rdt_TableCell {
  justify-content: left;
}

#dx .rdt_TableCol {
  justify-content: left;
  font-weight: bolder;
  font-size: small;
}
/* #dx .rdt_TableCell {
  justify-content: center;
} */
#dx .rdt_TableHeadRow {
  background-color: #d7d9e0;
  color: #000;
}
/* #dx .maincontent__card--header-title {
  font-size: 15px;
} */
.gocYik {
  min-height: 20px;
}
#dx .rdt_TableRow {
  font-size: 13px;
}
#dx .jzrolw {
  border-radius: 0;
}

#mainIconPathAttribute {
  fill: none;
  stroke: #000;
  cursor: pointer;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5px;
}
#IconChangeColor {
  cursor: pointer;
}
#article_popup {
  max-width: 800px;
}
#unpublishedDatatble {
  cursor: pointer;
}
.circle__loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #7d92a9;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  -webkit-animation: spin 2s linear infinite; /* Safari */
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#dx .clrRed {
  color: red;
}

#dx .gVZIpW {
  min-height: 36px;
}
#dx .span_text {
  font-size: 14px;
  display: flex;
  align-items: center;
}
/* #dx .accordion-item {
  margin-bottom: 1%;
} */

#dx .sidebar__icons--chevron {
  width: 25px;
  height: 25px;
  filter: hue-rotate(319deg);
}
/* .searchClientDT #cell-1-undefined{
  justify-content: center !important;
}
.searchClientDT .rdt_TableHeadRow [data-column-id="1"]{
  justify-content: center;
}
.mt-4 .rdt_TableHeadRow {
  justify-content: center !important;
}
.mt-4 .rdt_TableHeadRow [data-column-id="1"]{
  justify-content: center;
}
.mt-4 .rdt_TableHeadRow [data-column-id="8"]{
  justify-content: center;
}.mt-4 .rdt_TableHeadRow [data-column-id="9"]{
  justify-content: center;
} */
/* .mt-4 .rdt_TableHeadRow [data-column-id="9"]{
  justify-content: center;
} */
/* .mt-4 #cell-5-undefined{
justify-content: center !important;

}

.mt-4 #cell-6-undefined{
  justify-content: center !important;
} */
/* .mt-4 .rdt_TableHeadRow [data-column-id="2"]{
  justify-content: center;
} */

.apexcharts-menu-icon {
  display: none;
}

.accordion .accordion-button {
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  background: #d0d2d9 !important;
}
.accordion .accordionnew{
  border-radius: 0;
  border: none;
}
.accordion .accordion-collapse{
  padding: 8px;
  background: #e3e5ec;
    padding: 0;
}
#dx .author_font {
  font-size: 12px;
  color: #0b25f5;
  font-weight: 600;
}

#dx .author_font_sm {
  font-size: 14px;
}

#dx .maincontent__card--header {
  padding: 4px;
}

#dx .maincontent__postarticle--attachicon {
  background-color: #e2e2e2;
  border-radius: 32px;
  padding: 15px;
  width: 50px;
  height: 50px;
}

.myArticleDT #cell-1-undefined,
.myArticleDT #cell-7-undefined,
.myArticleDT #cell-8-undefined,
.myArticleDT #cell-9-undefined,
.kmUnpublishedDT #cell-1-undefined,
.kmUnpublishedDT #cell-8-undefined,
.kmUnpublishedDT [data-column-id="1"],
.kmUnpublishedDT[data-column-id="8"],
.kmdash_cont_table #cell-5-undefined,
.kmdash_cont_table #cell-4-undefined,
.kmdash_cont_table #cell-6-undefined,
.user_table #cell-2-undefined,
.user_table #cell-4-undefined,
.user_table #cell-5-undefined,
.clientDT_table #cell-1-undefined,
.clientDT_table #cell-5-undefined,
.clientDT_table #cell-6-undefined,
.clientDT_table [data-column-id="1"],
.clientDT_table [data-column-id="5"],
.clientDT_table [data-column-id="6"],
.project_DataTable [data-column-id="9"],
.wsr_datatable [data-column-id="5"],
.wsr_datatable [data-column-id="6"],
.wsr_datatable [data-column-id="7"],
.kmdash_cont_table [data-column-id="5"],
.kmdash_cont_table [data-column-id="6"],
.kmdash_cont_table [data-column-id="4"],
.myArticleDT [data-column-id="1"],
.myArticleDT [data-column-id="9"],
.myArticleDT [data-column-id="6"],
.myArticleDT [data-column-id="7"],
.myArticleDT [data-column-id="8"],
.kmUnpublishedDT [data-column-id="8"],
.user_table [data-column-id="2"],
.user_table [data-column-id="4"],
.user_table [data-column-id="5"],
.wsr_datatable #cell-5-undefined,
.wsr_datatable #cell-6-undefined,
.wsr_datatable #cell-7-undefined,
.mt-4rt [data-column-id="1"],
.mt-4rt [data-column-id="6"],
.myartifacts [ data-column-id="3"],
.myartifacts [ data-column-id="4"],
.contributorlist [data-column-id="4"]{
  justify-content: center !important;
}

.RewardsTable [data-column-id="3"],
.RewardsTable [data-column-id="4"],
.myartifacts [ data-column-id="3"],
.myartifacts [ data-column-id="4"],
.contributorlist [data-column-id="4"],
.myartifactsDT [ data-column-id="3"],
.myartifactsDT [ data-column-id="4"],
.myartifactsDT [ data-column-id="5"]{
  justify-content: center !important;
}


/* .RewardsTable #dx .rdt_TableCell,.RewardsTable #dx .rdt_TableCol #cell-3-undefined{
  justify-content: center !important;
}

.RewardsTable #cell-4-undefined{
  justify-content: center !important;
} */

/* #dx .rdt_TableCell [data-column-id="3"], #dx .rdt_TableCol [data-column-id="4"]{
  justify-content: center !important;
} */

#dx .user-profile {
  float: right;
  padding: 14px 16px;
  color: white;
}

#dx .user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #ddd;
  color: black;
}
#dx .activeHeart {
  color: #cd1a67;
}

input.maincontent__card--kmArticles-articleResponse:focus,
textarea.maincontent__card--kmArticles-articleResponse:focus {
  outline: none;
}

#dx input.maincontent__card--kmArticles-articleResponse,
#dx textarea.maincontent__card--kmArticles-articleResponse {
  border: none;
  background-color: #f2f2f4;
}
#dx .commentBoxClr {
  color: #6d1acd;
}
#dx hr.sortFeedLine {
  border-top-width: 0px;
}
#dx .postLikeCount {
  color: #7fcbb5;
}

#dx .clrAmber {
  color: #c6a926;
}

#dx .clrPurple {
  color: #a426c5;
}

#dx .font_smaller {
  font-size: smaller;
  color: black;
}

#dx .font_large {
  font-size: large;
  color: black;
}

.leaveCmtTextArea {
  width: 100%;
  border: 1px solid lightgrey;
  padding: 8px;
  border-radius: 4px;
}

.leaveCmt {
  margin: 20px 0px !important;
}

.leaveFormFields {
  border-radius: 0px !important;
}

.qa-container {
  width: 400px;
  margin: 0 auto;
}

/* Style for individual questions */
.question {
  font-weight: bold;
  color: #333;
  cursor: pointer;
  margin-left: 10px;
}

/* Style for answers, initially hidden */
.answer {
  display: none;
  margin-left: 20px;
  color: #666;
}

.chatgpt-question {
  border-radius: 10px 10px 10px 0px;
  padding: 8px;
  font-weight: 500 !important;
  color: black;
}

.chat-ai-backimage{
  background-image: url('./assets/img/AI\ background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px !important;
  height: 70vh;

}

.chatgpt-answer {
  border-radius: 10px 10px 10px 0px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400 !important;
  color: #105e82;
  white-space: pre-line;
}

.fixed-box-container {
  overflow: auto;
  /* height: 400px; */
}

.dot_loader {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #333;
  border-radius: 50%;
  margin-right: 3px;
  animation: dot-animation 1s infinite;
}

.dot_loader:nth-child(2) {
  animation-delay: 0.2s;
}

.dot_loader:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-animation {
  0%,
  20% {
    transform: scale(1);
    background-color: #333;
  }
  50% {
    transform: scale(1.5);
    background-color: #007bff;
  }
  100% {
    transform: scale(1);
    background-color: #333;
  }
}

#dx .article_btn {
  background-color: #441391 !important;
}

.article-events {
  background-color: rgba(237, 237, 237,70%);
  font-weight: bold;
  width: 100px;
}

::-webkit-search-cancel-button {
  cursor: pointer;
}
.css-bf9wz-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #040343 !important;
}

.MuiButtonBase-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* .MuiPagination-root {
  margin-left: 378px !important;
}
 */
.closeicon {
  color: #000;
  padding-top: 10px;
  font-size: 32px;
}

/* .articles__right { height: 250px; } */
.maincontent__postarticle--input text {
  color: #007bff !important;
}
.ck-editor__editable_inline {
  height: 150px;
}

#dx .ck-powered-by {
  display: none !important;
}

/* CSS for the sliding button */
.toggle-button {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  background-color: #ccc;
  border-radius: 40px;
  overflow: hidden; /* Ensure that the labels stay inside the button */
}

.toggle-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196f3; /* Color for the active state */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 40px;
}

.toggle-button input:checked + .slider {
  left: 50%; /* Move the slider to the right when checked */
}

.slider-text {
  position: absolute;
  text-align: center;
  line-height: 30px; /* Adjust the line-height for vertical centering */
  width: 100%;
  font-weight: bold;
  color: #333;
  transition: 0.4s;
  left: 0; /* Center the text by changing left position to 0 */
  right: 0; /* Center the text by changing right position to 0 */
  top: 5px; /* Adjust the top position for a slight downward shift */
  transform: translateX(0); /* Center the text inside the button */
}

.toggle-button input:checked + .slider + .slider-text.private {
  transform: translateX(50%); /* Move the text to the right when checked */
}

.registerimg {
  object-fit: contain;
  width: 284px;
  height: 177px;
  cursor: pointer;
}
.scheduled {
  background-color: #408ee4 !important;
}

.EventTable [data-column-id="4"],
.EventTable [data-column-id="5"],
.EventTable [data-column-id="7"] {
  justify-content: center !important;
}
/* .articles__myarticles--header {
  background-color: #fde9e9 !important;
}
.articlesread {
  background-color: #fde9e9 !important;
}
.articles__myarticles {
  background-color: #dee2fd !important;
}
.articles__events--header {
  background-color: #ededed !important;
}
.articles__events {
  background-color: #f5f5f5 !important;
} */
.eventsread {
  background-color: #ededed !important;
}
/* .eventsregister {
  background-color: #efefef !important;
} */

/* #dx .rdt_TableHeadRow {
  background-color: #fdc6dd !important;
} */
#dx .rdt_TableCell{
  background-color: #e3e5ec;
}
 #dx .rdt_Pagination .rdt_Pagination {
  /* background-color: #f2deea !important; */
 }
/* ImageComponent.css */

.fullscreen-container {
  background: url("../src/assets/img/new-dashboard/white\ cloud.png");
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* You can set a background color if needed */
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
}
/* ImageComponent.css */

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* You can set a background color if needed */
}

.fullscreen-image {
  max-width: 100%;
  max-height: 100%;
}

/* h1 {
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  margin-top: 20px;
} */
.centered-svg {
  max-width: 100%;
  max-height: 100%;
}

.content-container {
  text-align: center;
  margin-top: 20px; /* Add margin to create space between heading and buttons */
}

h1 {
  font-size: 60px;
  font-weight: bold;
}

.button-container {
  display: flex;
  gap: 20px; /* Adjust the spacing between buttons as needed */
  margin-top: 10px;
}

.employee-button {
  padding: 10px 20px;
  background-color: #48e9f1; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.executive-button {
  padding: 10px 20px;
  background-color: #74b72e; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
/* .button:hover {
  background-color: #0056b3; /* Button background color on hover */
/* } */

.sortpos {
  float: right;
  margin-top: -22px;
}

.accordionnew {
  background-color: #e4e6ed !important;
}
/* .accordionheader {
  background-color: #eceef6;
} */

.accordionheader button {
  /* background-color: #a6b1ff !important; */
  color: black !important;
}

/* .selectOptions .css-13cymwt-control {
  background-color: #ccd1e9 !important;
} */
.selectOptions .css-1xc3v61-indicatorContainer {
  color: #9ba8b5 !important;
}

#project .form-control {
  background-color: #ccd1e9 !important;
}

#padding-4 {
  padding: 4px !important;
}
#dx .maincontent__btn,
#dx .maincontent__bt:hover {
  border-radius: 40px;
  background-color: #050346;
}

.table-responsive {
  max-width: 100%;
  overflow-x: auto;
  transition: transform 0.3s ease;
  white-space: nowrap;
  cursor: pointer;
}

.table-responsive::-webkit-scrollbar {
  width: 8px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.draftBttn{
  display: flex;
  justify-content: end;
  position: relative;
  left: 80px;
}

.draftbtnMyArticle{
  background-color: #f22424;
}
.card-body #SvgjsSvg1248{
  height: 245px !important;
}
.card-body #SvgjsSvg1248 foreignObject{
  height: 245px !important;
}
.card-body .chart-container canvas{
  height: 252px !important;
}
.list-disc li{
  list-style-position: inside;
}

.text-blue-600{
  color: rgb(37 99 235);
}

.text-yellow-600{
  color: rgb(202 138 4);
}

/* .articles__article--para, .articles__article--para p  {
  font-size: 13px;
  line-height: 20px;
} */

/* .articles__article {
  padding: 40px 12px !important;
} */

.rdt_Pagination {
  background-color:transparent !important;
}
.card-body #apexchartsv5y8krv7{
  margin-top: 20px;
}
.card-body #SvgjsSvg1252, .card-body #SvgjsSvg1252 foreignObject{
  height: 225px;
}
/* #dx .card{
  max-height: 600px;
  overflow-y: auto;
} */

body {
  --ck-z-default: 100;
  --ck-z-modal: calc( var(--ck-z-default) + 999 );
}

.ck-body-wrapper{ z-index: 1300 !important;}

.pagi{
  margin-left: 32rem !important;
}

.registered {
  color: #427e20 !important;
  background-color: #fff !important;
  font-weight: bold !important;
}

/* chatgpt blinking loader css */

.chat__loader {
  text-align: center;
}

.typing-indicator {
  display: inline-block;
  text-align: left;
}

.typing-indicator span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 4px;
  background-color: #050346; /* Color of the dots */
  border-radius: 50%;
  animation: typing 2s infinite; /* Adjust animation duration as needed */
}

/* #dx .css-13cymwt-control{
  background-color: #ececec;
} */

@keyframes typing {
  0%, 20%, 80%, 100% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}
#myChart-license-text{
  display: none;
}

.required {
  color: red;
  margin-left: 2px; /* Adjust the spacing between label and asterisk as needed */
}

.articles__article--profilepic, .maincontent__card--kmDashboard-contributorsPic{
  display: none;
}

@media (min-width: 768px) {
  .md\:col-span-6{
    grid-column: span 6 / span 6;
  }
}

.chat-integration-circle {
  width: 135px;
    height: 120px;
    border-radius: 50%;
    position: absolute;
    right: -50px;
    top: -45px;
    margin-right: 0;
}

.chat-integration-img {
  position: absolute;
    right: 12px;
    top: 16px;
    font-size: 24px;
    width: 42px;
    height: auto;
}

.chat-integration-name {
  font-size: 1rem !important;
    line-height: 2.9rem !important;
}

.sharetable{
  width: 140% !important;
}

@media (max-width: 767px) {
  .mobileview {
    margin-right: 30px; /* Set margin-right to 30px for smaller screens */
  }
}

.margin {
  margin-left: 700px !important;
}

@media (max-width: 768px) {
  .margin {
    margin-left: 0px !important; /* Set margin-right to 30px for smaller screens */
  }
}
@media (max-width: 768px) {
.client {
  justify-content: end !important;
}
}

@media (max-width: 768px) {
  .download {
    margin-left: 7px;
    margin-bottom: 7px;
  }
  }

  .selectshare {
    height: 45px;
  }

  .midSection textarea {
    width: 100%;
    padding: 10px 14px;
    border: 2px solid #d7daddad;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px;
    font-size: 15px;
    color: black;
  }

  .geniechat {
    background-color: #fff;
    height: calc(100vh - 60px);
    width: 23rem;
    position: relative;
    position: fixed;
    right: 0;
    top: 64px;
    overflow-y: scroll;
    z-index: 1000;
  }

  .headerbg {
    padding: 4px;
    border-radius: 20px;
    background-color: #050346 !important;
}

.resumechatcontainer {
  height: 50px;
  width: 58px;
  background-color: #050346;
  position: fixed;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  border-radius: 10px;
  z-index: 1000;
}

.resumechat {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
}

.resumechaticon {
  width: 24px;
  height: 24px;
  font-size: larger;
  color: #fff;
}

.resumechattext {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 768px) {
  .sharetable {
  width: 100% !important;
  }
  }

  .articletype{
    width: 120px;
    height: 35px;
    background-color: transparent !important;
    border-width: 0;
  }

  .sanskrit-keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align buttons */
    margin-top: 10px;
  }
  
  .sanskrit-keyboard button {
    margin: 2px;
    padding: 10px;
    font-size: 18px;
    background-color: #f0f0f0; /* Light background for buttons */
    border: 1px solid #ccc; /* Light border for buttons */
    border-radius: 5px; /* Rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth background color transition */
  }
  
  .sanskrit-keyboard button:hover {
    background-color: #e0e0e0; /* Change background color on hover */
  }
  
  .keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    margin: 10px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    box-sizing: border-box; /* Ensure padding and border are included in the total width */
  }
  
  .keyboard button {
    flex: 1 1 48px; /* Allow button to grow and shrink */
    max-width: 48px;
    height: 48px;
    margin: 5px;
    padding: 0;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: #f0f0f0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    text-align: center;
  }
  
  .keyboard button:active {
    background-color: #e0e0e0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .keyboard button:hover {
    background-color: #e6e6e6;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .keyboard button:focus {
    outline: none;
  }
  
  /* Make the space button flexible and prevent overflow */
  .keyboard button.space {
    flex: 1 1 240px; /* Space bar takes up more space */
    max-width: 240px;
  }
  
  @media (max-width: 768px) {
    .keyboard button {
      flex: 1 1 40px;
      max-width: 40px;
      height: 40px;
      font-size: 16px;
    }
    
    .keyboard button.space {
      flex: 1 1 200px;
      max-width: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .keyboard button {
      flex: 1 1 36px;
      max-width: 36px;
      height: 36px;
      font-size: 14px;
    }
  
    .keyboard button.space {
      flex: 1 1 180px;
      max-width: 180px;
    }
  }
  
  @media (max-width: 320px) {
    .keyboard button {
      flex: 1 1 32px;
      max-width: 32px;
      height: 32px;
      font-size: 12px;
    }
  
    .keyboard button.space {
      flex: 1 1 160px;
      max-width: 160px;
    }
  }
  
  .keyboard-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  select {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 16px;
    cursor: pointer;
  }
  
  .keyboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }