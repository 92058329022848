.rhdiv {
  background-color: #0948b3 !important;
  color: white;
  padding: 10px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
/* 
.card {
    box-shadow: 1px 2px 4px 0px #979696 !important;
} */

.rhdiv p {
  display: inline;
  font-weight: 900;
  margin-left: 10px;
}

.rhdiv a {
  margin-right: 10px;
}

.cr-a {
  float: right;
  color: white;
}

.cr-a:hover {
  color: white;
  text-decoration: underline;
}

.csvpdfBtn {
  float: right;
  float: right;
  position: relative;
  right: 10px;
  top: 10px;
}

.csvpdfBtn button {
  background-color: #0948b3;
  border-color: #36f;
  border: none;
  border-radius: 5px;
  margin-right: 1px;
  color: white;
  margin-bottom: 10px;
}

.detaTable tr:nth-child(odd) {
  background-color: #f7f9fc;
}

.detaTable .cedtab:hover {
  background-color: #ddd;
}

.detaTable {
  padding: 10px;
  width: 98%;
  margin: 0 auto 10px auto;
  border: 1px solid #c5cee0 !important;
}

.detaTable th {
  background: #42aaff !important;
  padding: 10px;
  color: white;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;
  border: 1px solid #c5cee0 !important;
}

.detaTable tr {
  text-align: center;
  border: 1px solid #c5cee0 !important;
}

.detaTable td {
  height: 40px;
  border: 1px solid #c5cee0 !important;
  font-size: 12px;
}

.rodeinput {
  text-align: center;
  width: 98%;
  height: 40px;
  border: 1px solid rgb(208, 207, 207);
  border-radius: 4px;
  margin: 8px;
}

.Ccten {
  padding: 0 20px 30px 20px !important;
  font-size: 12px !important;
}

.rdt_TableCol {
  justify-content: center;
}

.rdt_TableCell {
  justify-content: center;
}

.addspace {
  margin: 10px;
}

.rightSideBtn {
  width: 100%;
  display: inline-block;
}

.rightSideBtn button {
  float: right;
}
