iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
    margin: 0;
}

main {
    overflow: hidden;
}

img {
    max-width: 100%;
}

strong {
    font-weight: $font-weight-bold;
}

button:focus {
    outline: 0;
}

label {
    font-weight: $font-weight-bold;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
    padding-left: 40px;
    padding-right: 40px;
}