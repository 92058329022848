.sidebar {
  background-color: #011A60;
  height: calc(100vh - 60px);
  width: 280px;
  position: relative;
  position: fixed;
  left: 0;
  overflow-y: scroll;
  &__hide {
    display: none;
    transition: all 0.5s ease-in;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &__secondary {
    width: 40px;
  }
  &__title {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0 0 1rem;
    color: #fff;
  }
  &__profile {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0 0 1rem;
    color: #fff;
    &--pic {
      width: 50px;
      height: auto;
      border-radius: 100%;
      display: none;
    }
    &--namesec {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & p {
        padding: 0;
        margin: 0;
      }
      &-status {
        color: #32cd32;
        font-size: 12px;
        display: none;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
      }
      &-icon {
        width: 8px;
        height: 8px;
      }
    }
  }
  &__ul {
    display: flex;
    flex-direction: column;
    // margin-top: 3rem;
    width: 100%;
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  &__li {
    list-style-type: none;
    list-style-position: inside;
    &--ul {
      padding-left: 0;
    }
    &--li {
      @extend .sidebar__li;
    }
    &--a {
      @extend .sidebar__a;
      padding-left: 4rem !important;
    }
  }
  &__a {
    color: #a9b5e6;
    text-decoration: none;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    span {
      color: rgba(255, 255, 255, 70%);
    }
    .fa-circle-chevron-right {
      color: rgba(255, 255, 255, 70%);
    }
    &:hover {
      background-color: #3A4A75;
      color: #f0f0f0;
      stroke: #f0f0f0;
      fill: #f0f0f0;
    }
    &--active {
      background-color: #3A4A75;
      span {
        color: #d4f2ff;
      }
    }
    &--span {
      display: flex;
      column-gap: 12px;
      align-items: center;
      min-width: 30px;
      &:hover::after [title] {
        background-color: #1658a0;
        border-radius: 8px;
        padding: 5px;
        color: #fff;
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    &--a {
      font-size: 12px;
      color: #a9b5e6;
      padding: 8px 1rem;
      text-decoration: none;
      &:hover {
        background-color: #222b76;
        color: #f0f0f0;
      }
    }
  }
  &__icons {
    /* width: 24px;
    height: 24px; */
    font-size: 24px;
    &--chevron {
      width: 16px;
      height: 16px;
    }
  }
}
.layout {
  display: grid;
  grid-template-columns: 1fr;
}
@media (max-width: 767px) {
  #dx {
    & .sidebar {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  #dx {
    & .sidebar {
      position: absolute;
      z-index: 1;
    }
  }
}
