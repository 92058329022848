.entrySelect select {
  width: 65px;
  border: 1px solid rgb(198, 198, 198);
  background-color: rgb(96, 100, 103);
}
.entries {
  background-color: rgb(67, 68, 70);
}

.addDept{
  min-width: 170px !important;
}