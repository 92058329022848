body {
  background-color: rgba(255, 255, 255, 1) !important;
  height: 100%;
}

/* .Main {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 35% 65%;
} */

/* .leftBox {
  background-image: url(../../assets/img/new_design/signin_banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
} */

.leftBox a {
  margin-left: 0.5rem;
}

/* .rightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  width: 100%;
  overflow: hidden auto;
  background-color: white;
  max-height: 100vh;
  position: relative;
}

.leftImg {
  background-size: contain;
  background-image: url(../../assets/img/SignupBG.png);
  width: 100%;
} */

.gethelpText {
  position: fixed;
  bottom: 5%;
  font-size: 15px;
  left: 4%;
  font-weight: 800;
  color: white;
}

.gethelpText a {
  text-decoration: underline;
  color: white;
}

/* .mainContainer {
  display: flex;
  width: 80%;
  justify-content: center;
  margin: 0 auto;
} */

.Container {
  width: 60%;
  margin-top: 10%;
}

/* .fbBtn{
    background-color: white;
    border: 1px solid rgb(143, 143, 143);
    border-radius: 8px;
    padding: 14px;
    width: 12%;
} */

.separator {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 14px;
}

.separator h2 {
  padding: 0 2rem;
  /* creates the space */
}

.separator .line {
  flex: 1;
  height: 1px;
  background-color: #d7daddad;
  border-radius: 2px;
}

.lineText {
  margin: 1rem;
  color: #8f8c94;
}

#signin input {
  margin-bottom: 10px;
}
.midSection input {
  width: 100%;
  padding: 10px 14px;
  border: 2px solid #d7daddad;
  border-radius: 8px;
  /* margin-top: 10px;
  margin-bottom: 30px; */
}
.input-success {
  border: 2px solid #009580 !important;
}

.input-error {
  border: 2px solid red !important;
}

/* 
.footerText {
  margin-bottom: 0;
  font-size: 12px;
  text-align: left;
  width: 100%;
}

footer {
  margin-left: 28%;
  margin-top: 10%;
  margin-bottom: 4%;
  width: 45%;
}

footer a {
  color: rgb(133 105 163);
  font-weight: bolder;
  text-decoration: none;
} */

.message {
  font-size: 1.2em;
  text-align: center;
  color: #36a936;
}

@media (min-width: 480px) and (max-width: 980px) {
  .Main {
    height: 100%;
  }

  .leftBox {
    display: none;
  }

  .rightBox {
    width: 100%;
    max-height: unset;
  }

  footer {
    margin-top: 5%;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .Main {
    height: 100%;
  }

  .mainContainer {
    width: 100%;
  }

  .googleBtn {
    width: 75%;
  }

  .fbBtn {
    width: 18%;
  }

  .leftBox {
    display: none;
  }

  .rightBox {
    width: 100%;
    max-height: unset;
  }

  .container {
    margin-top: 14%;
    padding: 0px 40px;
  }

  footer {
    margin-top: 5%;
  }
}

/* .errorsignin {
  position: absolute;
  margin-top: 4px;
} */

.errorsignin p {
  color: red;
  font-size: 12px;
}
