.reg-container {
  margin-top: 5%;
}

.registration-name {
  display: flex;
  justify-content: space-between;
}
.optional-text {
  font-size: 10px;
  color: #999;
  margin-top: -25px;
    
}
.registration-name .midSection {
  width: 48%;
}



.signupBtn {
  background-color: rgb(5, 8, 74);
  border: none;
  color: white;
  border-radius: 12px;
  padding: 11px !important;
  width: 100%;
  cursor: pointer;
}

.privacy-policy {
  display: flex;
  margin: 20px 0;
  align-items: baseline;
  gap: 10px;
}

.privacy-checkbox {
  margin-left: 4px;
  margin-bottom: 20px;
  margin-right: 5px;
}

.privacy-checkbox:checked::before {
  background-color: aqua;
}

.policy {
  width: 85%;
  margin-left: 10px;
  margin-top: 20px;
  font-size: 14px;
}
.reg-footer {
  margin-top: unset;
  /* margin-left: 26%; */
}
/* .red-line {
  color: red;
  padding-bottom: 30px;
  padding-left: 10px;
  margin-top: -20px;
} */
.green-line {
  color: green;
  padding-bottom: 30px;
  padding-left: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.signupBtn-dis {
  background-color: rgb(5, 8, 74);
  border: none;
  color: white;
  border-radius: 12px;
  padding: 11px !important;
  width: 100%;
  cursor: pointer;
  margin: 1rem 0 0 0;
  /* opacity: 0.4 !important; */
}
.errorsignin p {
  color: red;
  font-size: medium;
}

.pwddes {
  font-size: 9px;
  color: red;
  margin-top: -30px;
  font-weight: 600;
}
.errmargin {
  margin-top: 70px;
}
.pwderr {
  margin-top: 30px;
}
.pwdsucc {
  margin-top: 10px;
}

.pwdRestriction{
  color: red;
  font-size: 10px;
  font-weight: 600;
}

.newHighlight2{
  bottom: 12px;
  font-size: 9px;
  right: -206px;
}

.newHighlight{
  bottom: 12px;
  font-size: 9px;
}